import React from 'react';
import styled from 'styled-components';
import { headingShadow } from '../styles/text';
import { white } from '../styles/colours';
import { mq } from '../styles/media-queries';

const SlideLinkEdge = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.15);
  clip-path: polygon(100% 0, 100% 2px, 81px 2px, 4px 100%, 0 100%, 76px 0);
`;

const SlideLinkInner = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  color: ${white};
  padding: 20px 32px 20px 108px;
  background-color: rgba(0, 0, 0, 0.4);
  clip-path: polygon(80px 0, 100% 0, 100% 100%, 0 100%);
  font-size: 1.5rem;
  display: block;
  font-family: 'Dodger';
  font-style: italic;
  text-decoration: none;
  text-shadow: ${headingShadow};

  ${mq.large} {
    font-size: 1.25rem;
  }
  ${mq.medium} {
    font-size: 1.15rem;
  }
  ${mq.small} {
    font-size: 1rem;
    padding: 0.5rem 1rem 0.5rem 80px;
  }
`;

export const SlideLinkText = ({ children }) => (
  <SlideLinkInner>
    {children}
    <SlideLinkEdge />
  </SlideLinkInner>
);
