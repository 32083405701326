export const slideData = [
  {
    heading: 'ONLINE/HOME FITNESS TRAINING',
    imgSrc: null,
    imgAlt: 'Body Best Fitness - Home Fitness Training',
    href: '/services/home-fitness-training',
  },
  {
    heading: 'CORE AND STABILITY TRAINING',
    imgSrc: null,
    imgAlt: 'Body Best Fitness - Core Stability Training',
    href: '/services/core-and-stability-training',
  },
  {
    heading: 'DUMBBELLS TO WEDDING BELLS',
    imgSrc: null,
    imgAlt: 'Body Best Fitness - Training For Your Wedding',
    href: '/services/wedding-fitness-training',
  },
  {
    heading: 'FIGHTING FIT BOXING',
    imgSrc: null,
    imgAlt: 'Body Best Fitness - Fighting Fit Boxing',
    href: '/services/fighting-fit-boxing',
  },
];
