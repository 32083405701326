import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import SlickCarousel from 'react-slick';
import './slick.css';
import './slick-theme.css';
import { SlideLinkText } from './slide-link-text';
import { SlideTitle } from './slide-heading';
import { slideData } from './slide-data';

export const Slideshow = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    arrows: false,
    dots: true,
  };

  const data = useStaticQuery(graphql`
    fragment imgSrc on File {
      childImageSharp {
        original {
          src
        }
      }
    }

    query {
      slide1: file(
        relativePath: { eq: "slideshow/slide-1-home-fitness-1440w.jpg" }
      ) {
        ...imgSrc
      }
      slide2: file(
        relativePath: { eq: "slideshow/slide-2-core-stability-1440w.jpg" }
      ) {
        ...imgSrc
      }
      slide3: file(
        relativePath: { eq: "slideshow/slide-3-wedding-fitness-1440w.jpg" }
      ) {
        ...imgSrc
      }
      slide4: file(relativePath: { eq: "slideshow/slide-4-boxing-1440w.jpg" }) {
        ...imgSrc
      }
    }
  `);

  slideData.forEach((s, idx) => {
    s.imgSrc = data[`slide${idx + 1}`].childImageSharp.original.src;
  });

  const slideLinkText = 'CLICK FOR MORE';

  const Slide = styled.a`
    text-decoration: none;
  `;

  return (
    <SlickCarousel {...settings}>
      {slideData.map(s => (
        <Slide key={`slide-${s.heading.replace(' ', '-')}`} href={s.href}>
          <img src={s.imgSrc} alt={s.imgAlt} />
          <SlideTitle>{s.heading}</SlideTitle>
          <SlideLinkText>{slideLinkText}</SlideLinkText>
        </Slide>
      ))}
    </SlickCarousel>
  );
};
