import React from 'react';

import {
  Layout,
  SEO,
  HDiv,
  H1,
  MoreHeading,
  SpecialtyHeading,
  SpecialtyList,
  ContentSection,
  ContentRight,
  BestQuote,
} from '../components';
import { Slideshow } from '../components/slideshow';
import { generateThumbnailData } from '../helpers/generateThumbnailData';
import {
  generateServiceSummaryItems,
  generateRandomNumberSequence,
} from '../helpers/summaryItems';
import { bodyItemType, bodyRenderer } from '../helpers/bodyRenderer';
import { SidePanelItemSolidDivider } from '../components/side-panel-item';

const IndexPage = ({ data }) => {
  const thumbnailData = generateThumbnailData(data.thumbnails);
  const rightSideBody = generateServiceSummaryItems(
    thumbnailData,
    bodyItemType.SIDE_PANEL_ITEM
  );
  const randomSequence = generateRandomNumberSequence(rightSideBody.length, 4);

  return (
    <Layout>
      <SEO title="Home" />
      <Slideshow />
      <H1>WELCOME TO BODY BEST FITNESS</H1>
      <ContentSection>
        <div>
          <p>
            Personal training that helps you start the journey to a fitter,
            healthier and more effective you. Whether you are looking to lose
            fat, gain muscle, reduce stress or increase your strength and
            flexibility we can help. By carefully analysing your personal goals,
            exercise history and fitness assessment, we will design an exercise
            program that is safe, effective and specifically tailored to your
            individual needs.{' '}
            <strong>You will see results - GUARANTEED!</strong>
          </p>
          <BestQuote>
            &ldquo;No result is impossible, it's simply a case of mind over
            matter!&rdquo;
          </BestQuote>
          <p>
            Best has been in the fitness industry since 2003. Based on his
            experience and knowledge with free weights, his main focus has been
            on integrated and functional training by incorporating more muscles
            into the workout, making it all the more beneficial. As an extreme
            fitness enthusiast Best goes above and beyond with a constant
            variation in exercise routines and encourages a healthy lifestyle.
          </p>
          <p>
            Whether you are a beginner or have been working out for years
            without seeing the results you wanted, Best can work with you to
            help you reach your goals.
          </p>
          <SpecialtyHeading>BEST SPECIALISES IN:</SpecialtyHeading>
          <SpecialtyList>
            <li>Abdominal and Core conditioning</li>
            <li>Swiss ball training</li>
            <li>Boxercise</li>
            <li>Olympic lifts</li>
            <li>Muscular endurance</li>
            <li>Weight loss</li>
            <li>Circuit training</li>
            <li>Flexibility training</li>
            <li>Body sculpting</li>
            <li>Total strength training</li>
            <li>Kettlebell training</li>
            <li>ViPR training</li>
          </SpecialtyList>

          <HDiv />
          <p>
            Best has full liability insurance, is first aid qualified and is a
            certified personal trainer.
          </p>
        </div>
        <ContentRight>
          <SidePanelItemSolidDivider />
          <MoreHeading>More</MoreHeading>
          {randomSequence.map(idx =>
            bodyRenderer(rightSideBody[idx], `right-side-${idx}`)
          )}
        </ContentRight>
      </ContentSection>
    </Layout>
  );
};

export const query = graphql`
  query {
    thumbnails: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "thumbnails" }
      }
    ) {
      nodes {
        publicURL
        name
      }
    }
  }
`;

export default IndexPage;
