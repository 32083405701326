import React from 'react';
import styled from 'styled-components';
import { mq } from '../styles/media-queries';

const SlideHeadingEdge = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-right: 3px;
  background-color: rgba(0, 0, 0, 0.15);
  clip-path: polygon(
    100% 0,
    calc(100% - 80px) 100%,
    0 100%,
    0 calc(100% - 2px),
    calc(100% - 81px) calc(100% - 2px),
    calc(100% - 4px) 0
  );
`;

const SlideHeading = styled.h2`
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  font-size: 1.8rem;
  font-style: italic;
  padding: 20px 118px 20px 38px;
  font-weight: normal;
  background-color: rgba(0, 0, 0, 0.4);
  clip-path: polygon(0 0, 100% 0, calc(100% - 80px) 100%, 0 100%);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  margin: 0;

  ${mq.large} {
    font-size: 1.5rem;
  }
  ${mq.medium} {
    font-size: 1.25rem;
  }
  ${mq.small} {
    font-size: 1rem;
    padding: 0.5rem 80px 0.5rem 1rem;
  }
`;

export const SlideTitle = ({ children }) => (
  <SlideHeading>
    {children}
    <SlideHeadingEdge />
  </SlideHeading>
);
